.project {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;

  margin: 0 0 2rem 0;

  &.project-image-right {
    flex-direction: row;

    .project-title, .project-description {
      padding: 0 rem(20) 0 0;
    }
  }

  @include breakpoint($tablet) {
    flex-wrap: wrap;
  }

  .project-title {
    display: block;
    order: 1;
    width: 100%;

    padding: 0 0 0 rem(20);
    margin: 0;

    @include breakpoint($tablet) {
      width: 60%;
    }
  }

  .project-image {
    display: block;
    order: 2;
    width: 100%;

    @include breakpoint($tablet) {
      order: 3;
      width: 40%;
    }

    img {
      width: 100%;
    }
  }

  .project-description {
    display: block;
    order: 3;
    width: 100%;

    padding: 0 0 0 rem(20);
    margin: 0;

    text-align: justify;

    @include breakpoint($tablet) {
      order: 2;
      width: 60%;
    }

    p {
      margin: 0 0 rem(20) 0;
    }
  }
}
