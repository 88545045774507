header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  background-color: $color-header-background;
  margin: 0;
  width: 100%;
  padding: rem(16) 4%;

  @include breakpoint($tablet) {
    position: sticky;
    top: 0;

    padding: rem(24) 5% rem(20);
  }

  @include breakpoint($desktop) {
    padding: rem(24) 18% rem(20);
  }
}

.header-title {
  width: 100%;
  margin-bottom: rem(10);

  @include breakpoint($tablet) {
    width: auto;
    margin-bottom: 0;
  }

  a {
    font-weight: bold;
    font-size: 1.1em;
    color: $color-text-default;
  }
}

.header-menu {
  width: 100%;
  text-align: center;
  padding: 0 rem(16);

  @include breakpoint($tablet) {
    width: auto;
  }

  a {
    display: block;

    padding: 1rem 0.5rem;
    color: $color-text-default;

    @include breakpoint($tablet) {
      display: inline;
      padding: rem(8) rem(16);
    }

    @include breakpoint($desktop) {
      padding: rem(10) rem(20);
    }

    &:link, &:visited {
      color: $color-text-default;
    }

    &:hover {
      background-color: $color-text-default;
      color: $color-button-background;
    }
  }
}
