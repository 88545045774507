html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  display: flex;
  flex-direction: column;
  font-family: $font-main;
  background-color: $color-background;
  color: $color-text-default;
  min-height: 100vh;
  margin: 0;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

a {
  text-decoration: none;
  color: $color-text-url;
  -webkit-transition: background-color .2s ease-in-out, color .2s ease-in-out;
  -moz-transition: background-color .2s ease-in-out, color .2s ease-in-out;
  transition: background-color .2s ease-in-out, color .2s ease-in-out;

  &:hover {
    text-decoration: none;
    color: $color-white;
  }
}

.button {
  padding: rem(2) rem(5) rem(2) rem(5);
  border: solid 1px $color-text-url;
  background-color: $color-button-background;
  color: #eaeeff;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
}

h1 {
  margin: rem(3) rem(10) rem(20);
  font-size: 1.75rem;
}

h2 {
  display: inline-block;
  margin: 1rem 1rem 0.3rem;
  font-size: 1.5rem;
}

h3 {
  margin: 2rem 2rem 0.3rem;
  font-size: 1.25rem;
}

div.main-bg
{
  padding-top: rem(40);
  padding-bottom: rem(40);
  margin: 0;
  width: 100%;
}

div.main
{
  margin-left: 2%;
  margin-right: 2%;

  @include breakpoint($tablet) {
    margin-left: 6%;
    margin-right: 6%;
  }

  @include breakpoint($desktop) {
    margin-left: 20%;
    margin-right: 20%;
  }
}

div.main ul {
  padding-left: 1rem;

  @include breakpoint($tablet) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

}

img.responsive {
  max-width: 100% !important;
  height: auto;
  display: block;
  margin: rem(16) auto;
}

pre
{
  display: block;
  background-color: #0e518d;
  margin: 10px 10px 20px;
  padding: 3px;
  border-style: dashed;
  border-width: 1px;
  overflow-x: auto;
}

li
{
  margin-bottom: 0.5em;
  text-align: justify;
}

.has-border {
  border: 1px solid $color-white;
}

.text-justify {
  text-align: justify;
}
