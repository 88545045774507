.about-table {
  max-width: rem(500);
  margin: rem(35) auto 0 auto;

  td {
    vertical-align: top;

    &:first-child {
      text-align: right;
      padding-right: rem(12);
      width: rem(110);
    }

    &.about-table-title {
      text-align: center;
      padding: 1rem 0;
      font-weight: bold;
      font-size: 1.2rem;
    }
  }
}
