/*
  Font variables
*/
$font-main: 'Courier New', 'Courier', monospace;

/*
  Breakpoints
*/
$xlarge: 1805px;
$mlarge: 1480px;
$large: 1279px;
$desktop: 1110px;
$tablet: 768px;

/*
  Color and color maps
*/

$color-white: #ffffff;
$color-black: #000000;

$color-background: #2E3234;
$color-text-default: #eaeeff;
$color-text-url: #88badd;

$color-header-background: #3481B8;

$color-main-background: #212a6c;

$color-button-background: #3481B8;

// User login/password reset colors
$color-map-admin-user: (
green: #00ce81,
label:#555555,
link: #808080,
link-hover: #b23b3b
);
