.blog-title-wrapper
{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 90%;
  margin: auto;
}

.blog-title
{
  font-weight: bold;
  font-size: 1rem;
}

.blog-date
{
  font-size: 1rem;
}

.blog-post
{
  text-align: justify;
  padding: 1%;

  @include breakpoint($tablet) {
    padding: 1.5rem;
  }

  img
  {
    max-width: 100% !important;
    height: auto;
    display: block;
    margin: rem(20) auto;
  }
}

.blog-prevnext
{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 90%;
  margin: auto;
}

.blog-prevnext a
{
  font-weight: bold;
  padding: 0.5rem 1rem;
  background-color: $color-button-background;
  color: $color-text-default;
}

.blog-prevnext a:link, table.blog-prevnext a:visited
{
  color: $color-text-default;
}

.blog-prevnext a:hover
{
  background-color: $color-text-default;
  color: $color-header-background;
}
